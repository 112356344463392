* {
    box-sizing: border-box;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

.container {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    position: relative;
}

.input_container {
    width: 100%;
    min-width: 0;
    max-width: 430px;
    position: relative;

    &::before {
        left: 0;
        right: 0;
        bottom: 0;
        content: '\00a0';
        position: absolute;
        transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        /* border-bottom: solid 1px #979797; */
        pointer-events: none;
    }

    &::after {
        left: 0;
        right: 0;
        bottom: 0;
        content: '\00a0';
        position: absolute;
        transform: scaleX(0);
        transition: transform 150ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        border-bottom: solid 2px #3f51b5;
        pointer-events: none;
    }


}

.line {
    &:focus-within {
        &::after {
            transform: scaleX(1);
        }
    }
}

.input {
    width: 100%;
    padding: 6px 0px 5px;
    min-width: 0;
    max-width: 430px;
    border: none;
    outline: none;
    font-size: 16px;
    color: #090a3c;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;

    &:not([disabled]):hover {
        box-shadow: 0px 2px 0px 0px #000000;
    }

    &[disabled] {
        background-color: transparent;
        /* pointer-events: none; */
    }
}

.input::placeholder {
    color: #b3b3b3;
}

.dropdown {
    width: 100%;
    min-width: 0;
    max-width: 430px;
    max-height: 250px;
    overflow-x: hidden;
    border: 1px solid #b3b3b3;
    background-color: #fff;
    visibility: hidden;
    z-index: 1001;
    position: absolute;
    top: 100%;
    padding: 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    &.v {
        visibility: visible;
    }
}

.dropdown_item {
    width: 100%;
    padding: 6px 16px;

    cursor: pointer;

    &:hover {
        background-color: #f1f1f1d7;
    }

    border-bottom: 1px solid #b3b3b36b;
}

.item_text {
    color: #090a3c;
    margin: 5px 0;
    font-size: 14px;
    min-height: auto;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
}

.city {
    margin: 0;
    font-size: 12px;
    color: transparent;

    &.a {
        color: rgba(0, 0, 0, 0.54);
        transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    &.v {
        color: #3f51b5;
        transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

}

.input-wrapper {
    position: relative;
    border-bottom: solid 1px #979797;
}

.clearIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.clearIcon::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 20px;
    top: 50%;
    right: 8px;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: rgba(0, 0, 0, 0.54);
}

.clearIcon::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 20px;
    top: 50%;
    right: 8px;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: rgba(0, 0, 0, 0.54);
}