.gorizontal-line {
  width: 100%;
  border-top: 1px solid var(--main-gray);
  margin: 20px 0;
  position: relative;
}

.gorizontal-line__title {
  font-size: 12px;
  position: absolute;
  right: 4%;
  top: -18px;
  color: #9e9e9e;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.gorizontal-line__icon {
  width: 40px;
  height: 40px;
  background-color: white;
  color: var(--main-gray);
  border-radius: 50%;
  position: absolute;
  top: -20px;
  left: 40px;
  border: 1px solid var(--main-gray);
  display: flex;
  align-items: center;
  justify-content: center;
}
