.gender-picker-modal {
  width: 40rem;
  height: 14rem;
  background: white;
  position: relative;

  display: flex;
  flex-direction: column;
}

.gender-picker-modal-background {
  background: white url("../../../assets/wave.png");
  background-repeat: no-repeat;

  background-size: 40rem 5rem;
  width: 40rem;
  height: 5rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.gender-picker-title {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: #8e24aa;
  padding: 0.5rem 0 0 0;
}

.gender-picker-body {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  z-index: 2;
}

.gender-picker-body .gender-picker-button {
  margin: 1rem;
  height: 10rem;
}

.gender-picker-body .gender-picker-button .description {
  font-size: 1.5rem;
}

.gender-picker-body .gender-picker-button .icon svg {
  width: 5rem;
  height: 5rem;
}
