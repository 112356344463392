.chatMessage {
  max-width: 85%;
}

.message {
  display: flex;
  flex-direction: row-reverse;
}

.message.out > .message-bubble {
  background-color: #3f51b5;
  color: #fff;
}

.message.out {
  flex-direction: row-reverse;
}

.message.out .message-info {
  color: rgba(255, 255, 255, 0.66);
}

.message.in > .message-bubble {
  color: "#757575";
}

.message.in {
  flex-direction: row;
}

.message.in .message-info {
  color: rgba(0, 0, 0, 0.5);
}

.message > .message-bubble {
  margin-top: 1rem;
  margin-bottom: 0;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  box-shadow: 0.0625rem 0.0625rem 0.625rem 0 rgba(0, 0, 0, 0.14);
  min-height: 1rem;
}

.message > .message-bubble .message-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.1rem;
  min-width: 1rem;
}

.message > .message-bubble .message-info .seen-mark {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 0.25rem;
}

.message > .message-bubble .message-info svg {
  width: 0.8rem;
  height: 0.8rem;
}

.chatMessage:first-child .message.in::before {
  box-sizing: border-box;
  border: 0.5rem solid #fff;
  width: 0;
  height: 0;
  content: "";
  transform: rotate(45deg);
  border-right-color: transparent;
  border-top-color: transparent;
  border-left-color: #fff !important;
  border-bottom-color: #fff !important;
  margin-top: 1.5rem;
  transform: rotate(45deg);
  box-shadow: -0.125rem 0.1875rem 0.25rem -0.1875rem rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  transform-origin: 0.75rem 1.5rem;
}

.chatMessage:first-child .message.out::before {
  box-sizing: border-box;
  border: 0.5rem solid #3f51b5;
  width: 0;
  height: 0;
  content: "";
  transform: rotate(45deg);
  border-right-color: #3f51b5 !important;
  border-top-color: #3f51b5 !important;
  border-left-color: transparent;
  border-bottom-color: transparent;
  margin-top: 1.5rem;
  box-shadow: 0.125rem -0.125rem 0.25rem -0.1875rem rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  transform-origin: 0.1875rem -0.3125rem;
}

.messageContainer .message-popover .MuiPopover-paper p {
  margin: 0.2rem 0.25rem;
}

.messageContainer.in .chatMessage:not(:first-child) {
  margin-left: 0.9375rem;
}

.messageContainer.out .chatMessage:not(:first-child) {
  margin-right: 0.9375rem;
}
