.complains-user-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-weight: bold;
  word-break: keep-all;

  cursor: pointer;

  width: 7rem;
}

.complains-user-container .complains-user-name {
  min-height: 3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-info-container {
  color: white;
  background-color: #9c27b0;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.1rem 0.25rem;
  margin: 0.25rem 0;
  border-radius: 3px;
  line-height: 2rem;
}

.filter-info-container button {
  margin: 0 0.25rem;
  cursor: pointer;
  color: white;
  background-color: #9c27b0;
  border: none;
}

.complaint-message {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.complaint-message-tooltip {
  background-color: white;
  color: black;
}

.complaints-table-control {
  cursor: pointer;
  background-color: white;
  border: none;
}

.complaints-table-control-cell {
  padding-left: 0.1rem !important;
  padding-right: 0.1rem !important;
}
