body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body iframe {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.popover-message {
  color: #999999;
  margin: 0.2rem 0.25rem;
  font-size: 0.8rem;
}

@media screen and (min-width: 600px) {
  html {
    font-size: 16px;
  }

  .message-text-container {
    font-size: 1rem;
  }

  .message-info {
    font-size: 0.75rem;
  }

  .chat-list-item-username {
    font-size: 0.9rem;
  }

  .chat-list-item-message {
    font-size: 0.75rem;
  }

  .chat-list-item-time {
    font-size: 0.675rem;
  }
}

@media screen and (max-width: 599px) and (min-width: 400px) {
  html {
    font-size: 10px;
  }

  .message-text-container {
    font-size: 1.5rem;
  }

  .message-info {
    font-size: 1.2rem;
  }

  .chat-list-item-username {
    font-size: 1.2rem;
  }

  .chat-list-item-message {
    font-size: 1rem;
  }

  .chat-list-item-time {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 399px) {
  html {
    font-size: 7px;
  }

  .message-text-container {
    font-size: 1.75rem;
  }

  .message-info {
    font-size: 1.5rem;
  }

  .chat-list-item-username {
    font-size: 1.5rem;
  }

  .chat-list-item-message {
    font-size: 1.2rem;
  }

  .chat-list-item-time {
    font-size: 1rem;
  }
}

:root {
  --main-gray: #999999;
}

/* spicy-rice-regular - latin */
@font-face {
  font-family: "Spicy Rice";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/spicy-rice-v11-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("./assets/fonts/spicy-rice-v11-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./assets/fonts/spicy-rice-v11-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("./assets/fonts/spicy-rice-v11-latin-regular.woff") format("woff"),
    /* Modern Browsers */
    url("./assets/fonts/spicy-rice-v11-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("./assets/fonts/spicy-rice-v11-latin-regular.svg#SpicyRice") format("svg");
  /* Legacy iOS */
}