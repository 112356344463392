.img-gallery-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-gallery-modal * {
  outline: none !important;
}

.image-gallery {
  border: 1px solid grey;
}

.image-gallery-slide img {
  width: 100%;
  max-width: 100%; /*** for image bigger than screen size ***/
  height: auto;
  object-fit: cover;
}

.react-photo-gallery--gallery img {
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 1px lightgrey;
}
