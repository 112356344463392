.is-online {
  background-color: limegreen;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 12px;
}

.last-seen {
  color: var(--main-gray);
  font-size: 0.8rem;
  margin-left: 12px;
}
