.messageContainer {
  flex: 2 2 auto;
  display: flex;
  flex-direction: column;
}

.messageContainer.in {
  align-items: flex-start;
}

.messageContainer.out {
  align-items: flex-end;
}

.img-avatar {
  object-fit: cover;
}
