.settings-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  padding: 20px 0;
}

.settings-actions__item {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10%;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 16px;
}

.settings-actions__item .rules-personal-info {
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.settings-actions__item .rules-personal-info a {
  margin: 8px;
  text-transform: uppercase;
}